import { BaseSearchbarHistoryItem, SearchbarHistoryItem } from '../../redux/searchbar/types'
import { getSearchDate } from '../../utils/miscellaneous-utils'
import { HeroSlideTransport, PopularDestinationTransport } from '../../transports/region-landing-page-transport'
import { trackingClient } from '../../clients'
import {
    SMALL_MOBILE_IMAGE_RESIZE_WIDTH,
    MEDIUM_MOBILE_IMAGE_RESIZE_WIDTH,
    LARGE_MOBILE_IMAGE_RESIZE_WIDTH,
    WIDE_SCREEN_IMAGE_RESIZE_WIDTH,
    DESKTOP_IMAGE_RESIZE_WIDTH,
} from './constants'
import { ListingSectionObject } from '../../transports/common/listing-section-transport'

export const verifyEmail = (email: string) => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return mailFormat.test(email)
}

export const dateToString = (date: Date | null, separtor: string, reverse = false) => {
    if (!date) {
        return null
    }
    const data = [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear(),
    ]

    return reverse ? data.reverse().join(separtor) : data.join(separtor)
}

export const getMonthLabel = (monthLabels: Array<string>) => (date: Date) =>
    monthLabels[date.getMonth()] + ' ' + date.getFullYear()

export const getHistoryObject = (
    startDate: Date | null,
    endDate: Date | null,
    adults: number,
    children: number,
    location: string,
): BaseSearchbarHistoryItem => ({
    arrival: startDate,
    departure: endDate,
    adults: adults > 0 ? adults : 1,
    children: children > 0 ? children : 0,
    search: location,
})

// replaced by new BFF endpoint integration to search api on fastlane
export const getSearchObject = (
    startDate: Date | null,
    endDate: Date | null,
    persons: number,
    location: string,
    regionId: number,
) => {
    return {
        arrival: getSearchDate(startDate),
        departure: getSearchDate(endDate),
        persons: persons > 0 ? persons : 1,
        search: location,
        regionId: regionId,
    }
}

export const historyToSearchObject = ({ arrival, departure, adults, children, search }: SearchbarHistoryItem) => {
    return {
        arrival: getSearchDate(arrival),
        departure: getSearchDate(departure),
        persons: adults + children,
        search,
    }
}

export const _scrollIntoView = (href: string) =>
    document.getElementById(href.split('#').pop() as string)?.scrollIntoView({ behavior: 'smooth', block: 'center' })

export const groupBestDestinations = (list: PopularDestinationTransport[], slidesInColumn: number) => {
    return list.reduce(
        (
            accumulatedValues: PopularDestinationTransport[][],
            currentValue: PopularDestinationTransport,
            currentIndex: number,
        ) => {
            const relativeIndex = currentIndex / slidesInColumn
            if (relativeIndex === Math.floor(relativeIndex)) {
                accumulatedValues.push([])
            }

            accumulatedValues[Math.floor(relativeIndex)].push(currentValue)

            return accumulatedValues
        },
        [],
    )
}

export const getTranslationArray = (numberOfDots: number) => {
    const translationArray = []
    let offset = 0
    for (let i = 0; i < numberOfDots; i++) {
        offset = i < 3 || i > numberOfDots - 3 ? offset : (offset += 10)
        translationArray.push(offset)
    }
    return translationArray
}

const isUrlAbsolute = (url: string) => {
    try {
        new URL(url)
        return true
    } catch {
        return false
    }
}

export const getPropertyClickRedirectUrl = (baseUrl: string, href: string) => {
    try {
        return new URL(href)
    } catch {
        return new URL(href, baseUrl)
    }
}

export const trackListings = (body: any) => {
    trackingClient.trackRegionLandingPageListings(body).then()
}

export const getImageAltText = (listingObject: ListingSectionObject) => {
    const { seoRegion, title, apartment, breadcrumbs } = listingObject
    const propertyTitle: string = title ?? apartment?.title ?? 'Property'
    const regionName: string = breadcrumbs?.length
        ? breadcrumbs[breadcrumbs.length - 1]?.name ?? seoRegion?.name ?? 'Region'
        : seoRegion?.name ?? 'Region'

    return `${propertyTitle} - ${regionName}`
}

export const getImageUrl = (format: string, slide?: HeroSlideTransport | null) => {
    if (!slide) {
        return null
    }
    const { image } = slide
    const { url, formats } = image || {}
    const _getResizedImageUrl = (width: number) => getResizedImageUrl(url, width)
    switch (format) {
        case 'small-mobile':
            return formats?.small?.url || _getResizedImageUrl(SMALL_MOBILE_IMAGE_RESIZE_WIDTH)
        case 'medium-mobile':
            return formats?.medium?.url || _getResizedImageUrl(MEDIUM_MOBILE_IMAGE_RESIZE_WIDTH)
        case 'large-mobile':
            return formats?.large?.url || _getResizedImageUrl(LARGE_MOBILE_IMAGE_RESIZE_WIDTH)
        case 'desktop':
            return _getResizedImageUrl(DESKTOP_IMAGE_RESIZE_WIDTH)
        default:
            return _getResizedImageUrl(WIDE_SCREEN_IMAGE_RESIZE_WIDTH)
    }
}

export const getResizedImageUrl = (imageUrl: string | undefined, width: number) => {
    if (!imageUrl) {
        return null
    }
    const url = new URL(imageUrl)
    url.searchParams.set('imwidth', width.toString())
    return url.toString()
}
